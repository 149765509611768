//
// user.scss
// Use this to write your custom SCSS
//

.bg-primary {
    background-color: $primary !important;
    --si-gray-900: white !important;
    --si-body-color: white !important;
    --si-nav-link-color: white !important;
    z-index: 5;

    a {
        color: white !important;
    }

    color: white !important;
}

.page-wrapper {
    background-color: white;
    margin-top: 60px;
}

footer {
    min-height: 400px;
    hyphens: auto;
}

#footer-links {
    font-size: 14px;
}

* {
    hyphens: auto;
    -moz-hyphens: auto;
}

.logo {
    position: fixed;
    right: 0;
    bottom: 0;
    width: auto;
    height: 400px;
    padding: 10px 0;
    pointer-events: none;

    transition: all 1s ease-in-out;
    z-index: 9000;

    &.placed {
        translate: -100% -100%;
    }
}

.zm-1000 {
    z-index: 1000;
    position: relative;
}

body.scrolled .logo.placed {
    top: 100% !important;
}

.bx {
    line-height: inherit;
}

@media (max-width: 500px) {
    .logo {
        height: 200px;
    }
    .footer {
        text-align: center;
    }
}

.z-index-1000 {
    z-index: 1000;
}

.z-index-2000 {
    z-index: 20000;
}

.cover-img {
    object-fit: cover;
}

.text-2-col {
    column-count: 2;
    column-gap: 2em;
    text-align: justify;
}

/* manrope-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/manrope-v13-latin-regular.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/manrope-v13-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/manrope-v13-latin-500.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/manrope-v13-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/manrope-v13-latin-600.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/manrope-v13-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* manrope-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/manrope-v13-latin-700.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('../fonts/manrope-v13-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
    font-family: 'Manrope', sans-serif;
    font-size: 1em;
}

$brand-color: #bcd233;

.overlay,
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    --ad-modal-content-width: 70vw;
}

.modal-form {
    border-radius: 0;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.formkit-form {
    .formkit-input,
    .formkit-select-icon {
        border-radius: 0;
    }

    .formkit-label {
        color: $brand-color;
        font-weight: bold;
    }

    .formkit-help {
        color: $brand-color;
    }

    .formkit-message {
        color: red;
    }

    .formkit-decorator-icon {
        fill: $brand-color;
    }

    .formkit-option {
        color: $brand-color;
    }

    [data-type='submit'] .formkit-input {
        background: $brand-color !important;
        border: none !important;
    }

    button {
        background-color: $brand-color;
        color: #ffffff;
        border: none;
        border-radius: 0;
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: darken($brand-color, 10%);
        }
    }
}

.overlay {
    color: #ffffff !important;
    font-size: 18px;
    text-align: center;
    div {
        border-radius: 10px;
    }

    a {
        color: #ffffff !important;
    }
    h6 {
        color: #ffffff !important;
        font-size: 26px;
    }
}
